$primary: #58afd1; // rgb(59, 128, 232);
$secondary: #ffa193;
$secondary2: darken($color: $secondary, $amount: 10);
$primary2: lighten($color: $primary, $amount: 25);
$primary3: darken($color: $primary, $amount: 20);
$light: rgb(204, 204, 204);
$light2: #dae5f0;// #D9E8F4;
$light3: rgb(188, 188, 188);
$dark: rgb(40, 40, 40);
$dark2: rgb(11, 25, 46);
$light-dark: rgb(48, 47, 50);
$highlight-light: $primary3; // rgb(0, 63, 199); // primary3 #003BAE
// $text-light: rgb(49, 67, 90);
$highlight-dark: $primary2;
$bright: #f14b32;
$default-radius: 0.8em;
$media-size: 620px; //550px

$darkblue1: rgb(0,62,128);
$darkblue2: rgb(40, 41, 128);
$yellow: #ffe593;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: auto;

  font-weight: 300;
}

body, input[type="text"], input[type="email"], textarea {
  font-family: 'Arial','Work Sans', sans-serif;// 'Scope One', serif;
  font-size: 1.3rem;
  line-height: 1.4;
  background-color: $dark;

  @media screen and (max-width: $media-size) {
    font-size: 1rem;
  }
}

.main {
  display: flex;
  height: 100vh;
  padding-top: 40px; // for navbar

  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: space-evenly;
}

.js-warning {
  background: rgba($color: $secondary, $alpha: 0.7);
  border: 2px solid red;
  color: black;
  padding: 1rem;
  position: absolute;
  width: 100%;
  margin-top: 60px;
  transition: all 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: scale 5s ease-in-out;
  opacity: 1;

  p {
    align-items: center;
  }

  @media screen and (max-width: $media-size) {
    margin-top: 40px;
  }

  .X {
    float: left;
    padding-right: 40px;
    margin-left: 20px;
    width: 40px;
    height: 40px;
    color: $dark;

    .xbox {
      width: 40px;
      height: 40px;
      cursor: pointer;
      opacity: 0;
    }

    .line1 {
      width: 18px;
      height: 2px;
      background: $dark2;
      rotate: 130deg;
      margin-top: -25px;
      margin-bottom: 6px;
      margin-left: 10px;
      position: fixed;
    }

    .line2 {
      position: fixed;
      width: 18px;
      height: 2px;
      background: $dark2;
      rotate: -130deg;
      margin-top: -25px;
      margin-left: 10px;
    }
  }
}

.xbox {
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0.2;

  &:checked ~ .js-warning {
    opacity: 0;
    display: hidden;
  }
}

.Navbar {
  width: 100%;
  padding: 8px;
  height: 60px;
  background: $dark;
  position: absolute;
  color:#eee;
  display: flex;
  display: inline-block;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  letter-spacing: 1px;
  font-variant: small-caps;
  font-size: 18px;
  transition: opacity 0.1s ease-in-out;
  transition: height 1s ease-in-out;
  overflow: hidden;

  z-index: 90;

  @media screen and (max-width: $media-size) {
    opacity: 1;
    height: 40px;
  }

  .Logo {
    float: left;
    align-self: left;
    align-self: initial;
    border: 1px solid $light;
    padding-left: 10px;
    padding-top: 2px;
    width: 40px;
    min-width: 40px;
    border-radius: 0.4rem;
    margin-right: 0;

    margin-top: 7px; // for inline-block display
    transition: all 1s ease-in-out;

    font-family: 'Scope One', serif;
    @media screen and (max-width: $media-size) {
      transform: translate(0, -10px);
    }
  }



  .Pages {
    margin-top: 4px;
    transition: all 0.5s ease-in-out;
    margin-right: 0;
    margin-top: 10px; // for inline-block display
    
    @media screen and (min-width: $media-size) {
      padding-left: 70%; // 90%
      padding-right: 104px;
      padding-left:0%;
    }

    @media screen and (max-width: $media-size) {
      transition: all 0.5s ease-in-out;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      color: $dark;
      margin: 0;
      transition: all 0.1s ease-in-out;
      transform: translate(0px, 100px);
    }
  }

  .es {
    font-size: 16px;
    word-spacing: -3px;
    padding-top: 3px;

    @media screen and (max-width: 890px) {
      font-size: 14px;
      li {
        padding: 8px;
      }
    }
    @media screen and (max-width: 680px) {
      li {
        padding: 2px;
      }
    }
  }   



  .toggler {
    position: absolute;
    top: -5px;
    right: 6px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 100;
    opacity: 0; // 0 TOGGLER BOX
    display: block;

    @media screen and (min-width: $media-size) {
      display: none;
    }
  }


  .toggler:checked {
    & + .burger .line1 {
      transform: rotate(130deg);
      margin: 8px;
      width: 5px;
      margin-left: 4px;
      margin-top: 20px;
      overflow: hidden;
    }
    
    & + .burger .line2 {
      transform: rotate(260deg);
      margin: -14px;
      opacity: 0;
      overflow: hidden;
    }

    & + .burger .line3 {
      transform: rotate(-130deg);
      margin: 2px;
      width: 5px;
      margin-right: 20px;
      margin-top: 16px;
      margin-left: 14px;
      overflow: hidden;
    }
  }

  .burger-bun {
    position: absolute;
    top: 7px;
    right: 0px;
    height: 60px;
    display: block;
    overflow: scroll;
    overflow: hidden;
  }

  .burger div {
    width: 20px;
    height: 2px;
    background-color: $light;
    margin: 5px;
    overflow: hidden;

    @media screen and (min-width: $media-size) {
      transition: all 0.5s ease-in-out;
      opacity: 0;
      visibility: hidden;
    }

    padding-right: 10px;

    @media screen and (max-width: $media-size) {
      padding-left: 70%;
    }
  }

  .burger {
    float: left;
    overflow: hidden;
    margin-right: 15px;
    transition: all 0.5s ease-in-out;

    .line1, .line2, .line3 {
      transition: all 0.5s ease-in-out;
    }
  }

  .moveleft {
    float: left;
  }

  .moveright {
    float: right;
  }

  a {
    text-decoration: none;
    color: $light2;

    &:hover {
      transition: all 0.3s ease-in-out;
      color: $primary;
    }
  }

  li {
    list-style-type: none;
    display: inline;
    padding: 0rem 1.4rem; // 0 1.4 rem
    transition: all 0.4s ease-in-out; // 0.8s
    border-radius: $default-radius;

    @media screen and (max-width: 750px) {
      padding: 0rem 0.7rem;
      font-size: 14px;
    }
  }
}

.selection {
  position: absolute;
  top: 14px;

  top: 100px;

  border-radius: $default-radius;
  font-variant: small-caps;
  background-color: rgb(53, 52, 55);
  overflow: hidden;
  text-overflow: unset;
  justify-content: left;
  padding-left: 8px;
  padding-right: 0px;
  padding-top: 3px;
  color: $light;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;

  @media screen and (max-width: $media-size) {
    top: 14px;
    transform: translate(0px, -10px);
    left: 60px;
    right: 60px;
    opacity: 1;
    visibility: visible;
  }
}

.selection select {
  background-color: rgb(53, 52, 55);
  color: $light;
  padding-top: 3px;
  border: none;
  font-size: 18px;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  font-variant: small-caps;
  cursor: pointer;

  width: 90%;
  padding-right: 0px;
  padding-left: 3px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.selection::before {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  pointer-events: none;
  font-variant: small-caps;
}

.selection:hover::before {
  font-variant: small-caps;
}

.selection select option {
  padding: 5px 0px;
  font-variant: small-caps;
  font-size: 18px;
}

.selection2 {
  position: absolute;
  top: 10px;
  right: 3px;
  border-radius: $default-radius;
  font-variant: small-caps;
  background-color: rgb(53, 52, 55);
  overflow: hidden;
  text-overflow: unset;
  justify-content: left;
  padding-right: 0px;
  padding-top: 3px;
  color: $light;
  transition: all 0.4s ease-in-out;
  border: none;
  padding: 2px;
  cursor: pointer;
  transition: all 0.5s ease;
  opacity: 1;

  font-size: 16px;
  width: 110px;
  height: 40px;

  z-index: 100;

  &:hover {
    background-color: rgb(64, 63, 67);
  }

  i {
    padding: 0px 4px;
    translate: 0px 2px;
  }
  
  @media screen and (max-width: $media-size) {
    transform: translate(0px, -100px);
    left: 60px;
    right: 60px;
    opacity: 1;

    display: none;
  }
}

.opts {
  position: absolute;
  top: 60px;
  right: 0px;
  z-index: 20;
  width: 115px;
  color: $light3;
  border-radius: 1px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: translate(0, -50px);
  font-variant: small-caps;
  font-size: 1rem;

  div {
    animation: backInDown;
    animation-duration: 0.3s;
    cursor: pointer;
    margin: 1px 5px;
    background: $dark;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.2s ease;
    overflow-x: hidden;
    &:hover {
      background: rgb(40, 46, 65);
      color: $light2;
      transform: translate(-4px, 0px);
    }
  }
}

.opts-active {
  opacity: 1;
  display: block;
  transform: translate(0, 0px);
  animation: bounce;
}

.opts-inactive {
  display: none;
}

.menu {
  background: $dark2;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: visible;
  padding: 0rem;
  transition: all 0.6s ease-in-out;
  border-radius: 5rem;
  
  .menu-items {
    padding-top: 5rem;
    overflow-y: scroll;
    font-size: 16px;
  }

  opacity: 0;
  display: hidden;
  z-index: -1;
  margin-top: -740px;
  
  li {
    padding: 5px;

  }

  @media screen and (min-width: $media-size) {
    opacity: 0;
    display: hidden;
    z-index: -1;
  }

  .Pagelink {
    color: $light;
    text-decoration: none;
    list-style-type: none;
    transition: all 0.3s ease-in-out;
    overflow: visible;
    border-radius: 20rem;
    letter-spacing: 1px;
    font-variant: small-caps;
  }

  .shadow {
    opacity: 1;
  }

  .Pagelink:hover, .Pagelink:active {
    text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.379), 1px 1px 35px rgba(255, 255, 255, 0.502);
    box-shadow: 1px 1px 40px rgba(255, 255, 255, 0.222);
    background: rgba(255, 255, 255, 0.057);
  }
  .Pagelink:active {
    background: rgba(255, 255, 255, 0.295);
    box-shadow: 1px 1px 25px #eee;
  }
}

.menu-active {
  @media screen and (max-width: $media-size) {
    opacity: 1;
    display: flex;
    z-index: 80;
    margin-top: -25px;
    border-radius: 0;
    scale: 1;
    translate: 0;
    rotate: 0deg;   
  }

  @media screen and (max-height: 400px) {
    margin-top: -30px;
    li, a {
      padding-bottom: 3px;
      margin: 0px;
    }
  }
}

@mixin Title-template {
  background: rgba($primary, 0.2);
  border: 1px solid black;
  border-radius: 0.8rem;
  justify-content: center;
  text-align: center;
  margin: 2.5rem auto;
}

.banner {
  flex: 1 1 100%;
  background: $dark;
  background-image: url('./images/main_bg2_low.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 20rem;
  align-content: center;

  @media screen and (max-width: $media-size) {
    background-attachment: inherit;
  }
  @media screen and (max-height: $media-size) {
    background-attachment: inherit;
  }

  .cover {
    background: rgba($color: $primary, $alpha: 0.2);
    height: 100%;
    padding: 2rem;
  }

  .Title {
    @include Title-template;
    padding: 0.5rem 0;
    color: #eee;
    font-size: 2rem;
    word-spacing: 1rem;
    max-width: 12rem;
    font-variant: small-caps;
    letter-spacing: 1px;
    margin-top: 4.5rem;
    font-weight: normal;
    box-shadow: 0px 0px 3px $dark;
    transition: all 1s ease-out;
    overflow: hidden;

    &:hover {
      box-shadow: 0px 0px 15px $dark;
    }
  }

  .Title-cn {
    @include Title-template;
    padding: 1.5rem 0;
    color: #eee;
    font-size: 2rem;
    word-spacing: 1rem;
    max-width: 8rem;
    font-variant: small-caps;
    letter-spacing: 4px;
    margin-top: 4.5rem;
    font-weight: normal;
    box-shadow: 0px 0px 5px $dark;
    transition: all 1s ease-out;

    &:hover {
      box-shadow: 0px 0px 15px $dark;
    }
  }

  .Title2 {
    @include Title-template;
    padding: 0.5rem 0;
    color: $primary2;
    font-size: 1.4rem;
    max-width: 900px;
    letter-spacing: 1px;
    margin-bottom: 4rem;
    padding-left: 2px;
    padding-right: 2px;
    font-weight: normal;
    box-shadow: 0px 0px 2px $dark;
    transition: all 0.5s ease-out;

    &:hover {
      box-shadow: 0px 0px 10px $dark;
    }
  }

  .cn {
    letter-spacing: 4px;
    max-width: 800px;
    transition: all 1s ease-out;
  }

  .es {
    max-width: 700px;
  }

  span {
    color: #ddd;
  }
}

@mixin bloc {
  align-content: center;
  display: flex;
  padding: 1rem 1rem;
  align-items: center;
  width: 100%;

  p {
    max-width: 40rem;
    padding: 2rem;
    text-align: center;
    transition: all 1s ease-out;
  }

  .content {
    padding: 5rem 0rem;
    transition: all 1s ease-out;
    max-width: 800px;
  }

  .cn {
    letter-spacing: 2px;
  }

  span {
    color: $highlight-light;
  }
}

.cn {
  letter-spacing: 2px;
}

.cover2 {
  background: rgba($color: $dark, $alpha: 0.1);
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

@mixin side-img-template {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-clip: border-box;
}

.ai-bloc {
  @include bloc;
  color: darken($color: $primary, $amount: 15);
  background: linear-gradient(rgba(0,31,0,0.2), rgba(0,31,0,0.3)), url('images/python-ai-corners4.png');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: $media-size) {
    background-attachment: inherit;
  }

  @media screen and (max-height: $media-size) {
    background-attachment: inherit;
  }

  .Title {
    padding: 2rem 0rem;
    margin: 8rem auto; 
    border-radius: $default-radius;
    background: rgba($color: rgb(0,31,0), $alpha: 0.6);
    border: 1px solid $primary3;
    transition: all 1s ease-out;

    &:hover {
      box-shadow: 0px 0px 6px $primary3;
    }
  }

  .side-img {
    @include side-img-template;
    background-image: url('images/code_shot.png');
  }

  span {
    color: $light2;
  }

  @media screen and (min-width: $media-size) {
    .words {
      transition: all 0.5s ease-in-out;
      opacity: 0.5;
    }

    &:hover {
      p {
        opacity: 1
      }
    }
  }
}

.js-bloc {
  @include bloc;
  background: rgb(233, 233, 233);
  background-image: linear-gradient(rgba(233,233,233,0.2), rgba(233,233,233,1.0)), url('images/building_side.png');
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  font-weight: 400;

  @media screen and (max-width: $media-size) {
    background-attachment: inherit;
  }

  @media screen and (max-height: $media-size) {
    background-attachment: inherit;
  }

  .Title {
    @include Title-template;
    background: rgba($color: rgb(233,233,233), $alpha: 0.60);
    padding: 2rem 0rem;
    margin: 10rem auto;
    box-shadow: 0px 0px 1px $dark;
    transition: all 1s ease-out;

    &:hover {
      box-shadow: 0px 0px 8px $dark;
    }
  }

  .side-img {
    @include side-img-template;
    background-image: url('images/building_side.png');
  }

  .lighter {
    color: $primary3;
  }


}

.c-bloc {
  @include bloc;
  background: rgb(6, 6, 6);
  color: $light;
  background-image: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.9)), url('images/smoke.jpeg');
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;

  @media screen and (max-width: $media-size) {
    background-attachment: inherit;
  }

  @media screen and (max-height: $media-size) {
    background-attachment: inherit;
  }

  .content {
    padding: 2rem 0;
  }

  .Title {
    @include Title-template;
    background: rgba($color: rgb(6,6,6), $alpha: 0.60);
    border: 1px solid $light;
    margin: 6rem auto;
    transition: all 1s ease-out;

    &:hover {
      box-shadow: 0px 0px 7px $light;
    }
  }

  .side-img {
    @include side-img-template;
    background-image: url('images/smoke.jpeg');
  }

  span {
    color: $highlight-dark;
  }
}


@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.15s, $radius: $default-radius) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color 0s ease-in-out;
  position: relative;
  
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0;
    #{$horizontal}: 0;

    border-radius: $radius;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  &:hover {
    color: $hover;
    border-radius: $radius;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 1.2s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {
  @include btn-border-drawing(#58afd1, #ffe593, 4px, bottom, right);
}

.btn {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 1em 2em;
  letter-spacing: 0.05rem;
  border-radius: $default-radius;
  
  &:focus { outline: 2px dotted #55d7dc; }
}

.btn {
  @include btn-border-drawing(#58afd1, $bright, 4px, top, left);
  margin: 20px;
  margin-bottom: 0.5rem;

  &:hover {
    box-shadow: 0.1em 0.1em 5px 1px rgba(0,0,0,0.3);
  }

  &:active {
    box-shadow: none;
    color: red;
  }

  @media screen and (max-width: $media-size) {
    margin: 15px;
  }
}

.contact-bloc, form {
  background: $light2;
  width: 100%;
  grid-template-columns: 1fr;
  display: flex;
  justify-content: center;
  text-align: center; 

  margin: 0px;
  padding: 0px;

  h1, p {
    padding-bottom: 0.2rem;
  }

  .btn {
    @include btn-border-drawing(#58afd1, $bright, 4px, top, left);
    margin: 20px;
    margin-bottom: 5rem;

    &:hover {
      box-shadow: 0.1em 0.1em 5px 1px rgba(0,0,0,0.3);
    }

    &:active {
      box-shadow: none;
      color: red;
    }

    @media screen and (max-width: $media-size) {
      margin: 15px;
    }
  }

  .message-sent {
    padding: 2rem;
    padding-bottom: 8rem;
    i {
      padding: 2rem;
    }
  }

  .card {
    border-radius: 0.5rem;
    max-width: 30rem;
    justify-content: flex-start;
    padding: 3rem 0rem;
    overflow-y: hidden;
    padding-bottom: 11rem;
    padding-top: 4%;

    @media screen and (max-width: $media-size) {
      padding-bottom: 17rem;
    }
  }

  input, textarea {
    border-radius: 0.5em;
    resize: none;
    width: 70%;
    width: 80%;
    padding: 0.5rem;
    border: 1.5px solid gray;
    transition: all 0.4s ease-in-out;
    background: #eee;
    margin: 5px;
    
    &:hover {
      outline: none;
      box-shadow: 0.1em 0.1em 3px 1px rgba(0,0,0,0.3);
    }

    &:focus {
      border-color: $primary;
      width: 82%;
    }
  }

  textarea {
    width: 80%;
    height: 50%;

    @media screen and (max-width: $media-size) {
      height: 40%;
    }
  }
}


.BottomNav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  background-color: $dark;
  overflow-y: hidden;

  transition: all 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  align-items: center;
  justify-content: space-between;
  z-index: 99;

  i {
    opacity: 0;
    margin-left: 15px;
  }

  a {
    color: rgb(175, 188, 211);
    padding-top: 1rem;
  }

  @media screen and (max-width: $media-size) {
    opacity: 1;
    visibility: visible;
    display: flex;
    color: rgb(175, 188, 211);
    height: 3.2rem;

    a {
      padding: 3rem 1rem;
    }

    i {
      transition-delay: 0.5s;
      transition: opacity 2s ease-in-out;
      transition: color 0.3s ease-in-out;
      opacity: 1;
      padding: 3rem 0rem;
      padding-right: 1rem;

      &:hover {

        color: $secondary;
        text-shadow: 0px 0px 2px $secondary;
      }
    }
    transition: all 1s ease-out;

    @media screen and (min-height: $media-size) {
      height: 4.5rem;

      transition: all 1s ease-out;
      li, a {
        transform: scale(1.8);
      }
    }
  }
}

.about {
  display: flex;
  width: 100%;
  height: 90vh;
  background: #eee;
  margin-top: 60px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr 2fr auto;
  grid-template-rows: 1fr auto;

  text-align: center;
  align-content: space-between;

  .content-container {
    margin-right: 2rem;
    height: auto;
    align-items: center;
    display: flex;
    padding-top: 10%;
    max-width: 30em;

    .content {
      box-shadow: -1px -1px 5px #aaa;
      border-radius: $default-radius;
      margin: 20px 10px;
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: -1px -1px 15px #aaa;
      }

      p {
        padding: 1rem;

        span {
          color: rgb(183, 13, 13);
        }

        @media screen and (max-height: 480px) and (max-width: $media-size) {
          padding: 5px;
          font-size: 14px;
        }
      }

      @media screen and (max-height: 500px) and (max-width: 700px) {
        padding-bottom: 5rem;
      }
    }

    @media screen and (max-width: $media-size) {
      font-size: 1rem;
      margin-bottom: 50px;
      align-items: inherit;
    }

    @media screen and (max-height: 450px) and (max-width: $media-size) {
      padding-top: 0px;
      margin: 20px 5px;
      margin-bottom: 60px;
    }
  }
  
  @media screen and (max-width: $media-size) and (max-width: $media-size) {
    margin-top: 40px;
  }

  .img-container {
    height: 100%;
    background: #eee;
    background: url('./images/me_shadow_low.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.4s ease-in-out;

    @media screen and (max-height: 460px) and (min-width: 900px) {
      height: 180%;
    }
  }
}

.portfolio {
  background: #eee;
  height: 90vh;
  margin-top: 40px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  transition: all 0.3s ease-in-out;

  a {
    color: $primary3;

    &:hover {
      color: $secondary2;
    }
  }

  .scroll-this {
    z-index: 4;
    position: absolute;
    overflow-y: scroll;
    background: #eee; // rgb(228, 232, 235);
    box-shadow: inset 0px 0px 6px $light;
    color: $dark;
    height: 45%;
    width: 60%;
    top: 40%;
    left: 20%;
    padding: 1rem;
    border-radius: $default-radius;
    transition: all 0.2s ease-in-out;

    p {
      padding-bottom: 1rem;
    }

    .btn-wrapper {
      justify-items: center;
      justify-self: center;
      justify-content: center;
      display: flex;
      padding-top: 2rem;
      padding-bottom: 5rem;

      .btn {
        @media screen and (max-width: $media-size) {
          font-size: 14px;
        } 
      }
      
      i {
        scale: 1.2;
        transform: translate(5px,0);
      }
    }

    @media screen and (max-width: $media-size) {
      top: 110px; // 160px;
      left: 10%;
      width: 80%;
      height: 50%;
    }
  }

  .Title {
    position: absolute;
    z-index: 1;
    background: #eee;
    width: 100%;
    border-bottom: 1px solid $dark;
    margin-bottom: 1rem;
    padding-top: 1rem;
    overflow-x: hidden;
    left: 0px;
    
    h1 {
      padding: 1rem;
    }

    @media screen and (max-width: $media-size) {
      padding-top: 0px;

      font-size: 12px;
      h1 {
        padding-bottom: 0px;
      }
    }
  }
  
  .content {
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 1rem;

    @media screen and (min-width: 900px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: $media-size) {
      margin-top: 60px;
    }

    .card {
      scale: 1;
      height: 200px;
      background: $light;
      background: url('./images/code_shot2.png');
      background-size: cover;
      margin: 5px;
      border-radius: $default-radius;
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 0px 2px $dark;
      font-size: 1rem;


      &:hover {
        box-shadow: 0px 0px 15px $dark;

        h2, p {
          background: rgba($color: $light2, $alpha: 0.8);
        }
      }

      h2, p {
        padding: 1rem;
        background: linear-gradient(rgba($color: #eee, $alpha: 1.0), rgba(#eee, 0.6));
        height: 100%;
      }

      .card-info {
        display: hidden;
        height: 70vh;
        overflow: hidden;
      }

      .inner-card {
        width: 100%;
        height: 100%;
        border-radius: $default-radius;
        position: relative;

        // placeholder for flexbox
        box-shadow: 0px 0px 2px $dark;

        &:hover {
          box-shadow: 0px 0px 15px $dark;
          
          h2, p {
            background: rgba($color: $light2, $alpha: 0.8);
          }
        }

        h2, p {
          padding: 1rem;
          background: linear-gradient(rgba($color: #eee, $alpha: 1.0), rgba(#eee, 0.6));
          height: 100%;
        }
      }
    }

    .card-active {
      position: absolute;
      top: 20%;
      width: 90%;
      height: 70vh;

      z-index: 2;
      z-index: 4;
      overflow: hidden;

      h2, p {
        height: auto;
        padding: 1rem;
        background: rgba($color: #eee, $alpha: 0.9);
      }

      h2 {
        border-bottom: 1px solid $dark;
        padding-bottom: 5px;
        width: 100%;
        top: 0;
      }

      &:hover {
        h2, p {
          background: rgba($color: #eee, $alpha: 0.9);
        }
      }

      p {
        overflow: scroll;
        display: block;
      }

      .long-content {
        overflow-y: scroll;
        display: block;

        p {
          overflow: scroll;
          display: block;
        }
      }

      .card-info {
        padding-top: 3rem;
        padding-left: 15%;
        padding-right: 15%;
        padding-bottom: 5rem;
        opacity: 1;
        overflow: scroll;
      }

      .X {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
    }

    .card-zoomout {
      scale: 0.95;
      height: 200px;
      width: 100%;
      transition: all 0.1s ease-in-out;
      filter: brightness(0.9);
    }

    .card-zoomin {
      scale: 1.05;
      transition: all 0.3s ease-in-out;
    }

    .card-cover {
      transition: all 1s ease-in-out;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba($color: $dark, $alpha: 0.5);
      z-index: 1;
    }

    @media screen and (max-width: $media-size) {
      // font-size: 12px;
      grid-template-columns: 1fr;
      padding-left: 0;

      .card {
        height: 100px;

      }

      .card-active {
        top: 50px; // 100px;
        height: 270px;
        height: 70%; // (note: doesnt work in safari on mobile)
      }
    }
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding-bottom: 5rem;
    margin-bottom: 4rem;
    padding-top: 2rem;
    transition: all 0.6s ease-in-out;
    padding-left: 30%;
    overflow: hidden;

    a {
      color: $dark;

      &:active {
        color: white;
      }
    }

    i, a {
      justify-content: center;
      cursor: pointer;
      overflow: hidden;
      transform: translateX(2rem);
      transition: all 0.5s ease-in-out;

      padding-right: 2.5rem;
    }
    p {
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      opacity: 1;
      overflow: hidden;
    }

    .github-label {
      margin-left: 0rem;

      @media screen and (max-width: $media-size) {
        display: none;
      }
      
    }

    &:hover {
      a {
        color: $secondary2; // rgb(177, 55, 75);
        text-shadow: 0px 0px 8px $secondary;
        transition: all 0.5s ease-in-out;

        @media screen and (min-width: $media-size) {
          transform: translateX(80px);
        }
      }

      p {
        opacity: 0;
      }
    }
  }
}


.portfolio-old {
  background: #eee;
  height: 90vh;
  margin-top: 60px;
  margin-bottom: 0px;
  width: 100%;
  padding-left: 1rem;
  justify-content: center;

  .Title {
    position: absolute;
    z-index: 0;
    background: #eee;
    width: 100%;
    border-bottom: 1px solid $dark;
    margin-bottom: 1rem;
    padding-top: 1rem;

    @media screen and (max-width: $media-size) {
      width: 100%;
    }
  }

  @media screen and (max-width: $media-size) {
    height: 85vh;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-top: 60px;

    @media screen and (max-width: $media-size) {
      margin-bottom: 4rem;
    }

    .card {
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 440px;
      padding: 1rem;
      box-shadow: 1px 1px 5px $dark;
      border-radius: $default-radius;
      margin: 10px;
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: $media-size) {
        width: 300px;
        margin-left: 2px;
      }

      h2 {
        font-size: 24px;

        @media screen and (max-width: $media-size) {
          font-size: 18px;
        }
      }

      &:hover {
        box-shadow: 1px 1px 15px $dark;
      }
    }
  }

  @media screen and (max-width: $media-size) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
}


.resume {
  background: #eee;
  height: 90vh;
  margin-top: 60px;
  margin-bottom: 0px;
  width: 100%;
  padding: 1rem;

  a {
    text-decoration: none;
  }

  .btn {
    @include btn-border-drawing(#58afd1, $bright, 4px, top, left);
    margin: 20px;
    z-index: 2;
    margin-bottom: 0px;

    i {
      scale: 1.5;
      transform: translate(-4px, 2px);
    }

    &:hover {
      box-shadow: 0.1em 0.1em 5px 1px rgba(0,0,0,0.3);
    }

    &:active {
      box-shadow: none;
      color: red;
    }

    @media screen and (max-width: $media-size) {
      margin: 15px;
      margin-bottom: 0px;
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    z-index: 2;
    margin-bottom: 5%;

    @media screen and (max-width: $media-size) {
      position: absolute;
      top: 2rem;
      right: 2rem;

      .btn {
        background: rgba($color: #eee, $alpha: 0.65);
        font-size: 12px;
      }
    }
  }


  .content {
    width: 100%;
    border-bottom: 1px solid $dark;
    margin-bottom: 1rem;

    @media screen and (max-width: $media-size) {
      font-size: 12px;
    }
  }

  .fader {
    background: linear-gradient(rgba(#eee, 0.3), rgba(#eee, 1));
    position: fixed;
    z-index: 1;
    width: 96%;
    height: 100px;
    top: 70%;
    box-shadow: 0px -31px 1px rgba($color: #eee, $alpha: 0.4);
    overflow: hidden;
    margin-bottom: 0px;

  }

  .img-container {
    height: 70%;
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: $default-radius;
    margin-bottom: 0px;

    img {
      position: relative;
      display: block;
      border-radius: $default-radius;
      margin: 0px;
      padding: 0px;
      width: 100%;
      opacity: 0.8;
    }
  }

  @media screen and (max-width: $media-size) {
    margin-top: 40px;
    margin-bottom: 60px;
    margin-bottom: 0px;
  }
}

.Page404 {
  background-image: url('./images/navbar_drip.jpeg');
  background-size: cover;
  height: 100vh;
  display: block;
  overflow: hidden;

  .btn {
    @include btn-border-drawing($dark, $yellow, 4px, top, left);
    margin: 20px;
    margin-right: 44%;
    margin-left: 15%;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    overflow: hidden;

    &:hover {
      box-shadow: 0.1em 0.1em 5px 1px rgba(0,0,0,0.3);
    }

    &:active {
      box-shadow: none;
      border-radius: $default-radius;
      box-shadow: 0em 0em 25px rgba($yellow,1);
      background: rgba($color: $yellow, $alpha: 0.3);
      margin-bottom: 2rem;
    }
  }

  .content {
    margin-top: 20%;
    margin-left: 15%;
    overflow: hidden;

    @media screen and (max-width: $media-size) {
      margin-top: 25%;
      font-size: 14px;
    }

    @media screen and (max-height: 400px) and (max-width: 490px) {
      margin-top: 15%;
    }

    span {
      @media screen and (max-width: $media-size) {
        display: none;
      }
    }
  }
}

.test {
  position: absolute;
  top: 100px;
  left: 100px;
  height: 3rem;
  width: 3rem;
  background: $secondary;
}

[data-scroll] {
  transition: all 0.5s ease-in-out;
}

[data-scroll="in"] {
  opacity: 1;
}

[data-scroll="out"] {
  opacity: 0;
}


.dont-show {
  display: none;
}

.break {
  display: block;
  clear: both;
}

.glow {
  text-shadow: 0px 0px 2px $light;
  font-family: 'Long Cang', cursive;
  font-size: 2.5rem;
}